module.exports = [{
      plugin: require('../../../../build-cache/mr8tf9/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-VZ4TZ6V51Q"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":true,"exclude":["/preview/**","/do-not-track/me/too/"],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0}},
    },{
      plugin: require('../../../../build-cache/mr8tf9/node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"languages":["en","de"],"defaultLanguage":"en","fallbackLanguage":"en","generateDefaultLanguagePage":false,"localeJsonSourceName":"i18nLocale","localeJsonNodeName":"i18nLocales","siteUrl":"https://nighttec.net","i18nextOptions":{"defaultNS":"common","lowerCaseLng":true,"saveMissing":false,"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false},"pages":[{"matchPath":"/:lang?/shop/:handle","getLanguageFromPath":true},{"matchPath":"/:lang?/shop/category/:handle","getLanguageFromPath":true},{"matchPath":"/settings","languages":["en"]},{"matchPath":"/datenschutzerklaerung","languages":["de"]},{"matchPath":"/:lang?/rental","getLanguageFromPath":true},{"matchPath":"/:lang?/agb","getLanguageFromPath":true},{"matchPath":"/:lang?/impressum","getLanguageFromPath":true}]},
    },{
      plugin: require('../../../../build-cache/mr8tf9/node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../../build-cache/mr8tf9/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#fff","theme_color":"#fff","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ef4be863c809ed38cb8c1ea66419db5a"},
    },{
      plugin: require('../../../../build-cache/mr8tf9/node_modules/gatsby-source-shopify-translations/gatsby-browser.js'),
      options: {"plugins":[],"shopName":"nighttec.myshopify.com","shopifyPassword":"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","accessToken":"6b008010a5bd1823039b6ebb74ba0c4c","defaultLang":"en","prefixDefault":true,"locales":["en","de"],"waitingGatsbySourceShopify":5000,"sourceOnlyMode":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../../build-cache/mr8tf9/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
